<template>
	<div class="contest">
		<!-- Главный блок -->
		<div class="contest-cover">
			<div class="contest-cover-pic">
				<div
					class="image-cover-wrap"
					:style="'/img/contest-cover-7.jpg' | bgrImageCover"
				/>
			</div>
			<div class="contest-cover-content">
				<div class="content">
					<div class="grid">
						<div class="box">
							<h3 class="page-title">
								Lift meetup <br>Golang
							</h3>
							<div class="text">
								<p>
									13 марта в субботу в 14:00 МСК <br>
									пообщайся с разработчиками Go <br>
									из компаний МТС, МТС банк, Озон
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Главные кнопки -->
		<section class="section-simple wow">
			<div class="content">
				<div class="main-button-group">
					<div
						class="grid"
						style="text-align: center"
					>
						<div class="box">
							<button
								class="main-button orange"
								@click="$scrollTo($refs.regin)"
							>
								<span class="main-button-text">Зарегистрироваться</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-user-plus-white.svg' | prodSrcImage"
										alt=""
									>
								</span>
							</button>
						</div>
						<div class="box">
							<button
								class="main-button blue"
								@click="$scrollTo($refs.program)"
							>
								<span class="main-button-text">Программа</span>
								<span class="main-button-icon">
									<img
										:src="
											'/img/icons/icon-program-list-white.svg' | prodSrcImage
										"
										alt=""
									>
								</span>
							</button>
						</div>
						<div class="box">
							<button
								class="main-button gray"
								@click="$scrollTo($refs.speakers)"
							>
								<span class="main-button-text">Спикеры</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-speak-white.svg' | prodSrcImage"
										alt=""
									>
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Программа -->
		<section
			ref="program"
			class="section-simple light wow"
		>
			<div class="content">
				<h3 class="block-title">
					Программа на 13 марта
				</h3>
				<div class="program-group">
					<div class="grid">
						<div class="box">
							<div class="program-item">
								<div class="program-time">
									14:00 - 14:45
								</div>
								<div class="program-content">
									<div class="program-title">
										Илья Казначеев, МТС
									</div>
									<div class="program-text">
										Распределение транзакции на основе доменных автоматов
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="program-item">
								<div class="program-time">
									14:45 - 15:30
								</div>
								<div class="program-content">
									<div class="program-title">
										Юрий Бедарев, МТС
									</div>
									<div class="program-text">
										Построение сервиса на основе hexagon architecture
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="program-item">
								<div class="program-time">
									15:30 - 16:45
								</div>
								<div class="program-content">
									<div class="program-title">
										Дмитрий Гордеев, МТС банк
									</div>
									<div class="program-text">
										Унификация pub/sub взаимодействия и как спрятать ненужные
										детали под капот
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="program-item">
								<div class="program-time">
									15:30 - 16:15
								</div>
								<div class="program-content">
									<div class="program-title">
										Алексей Клементьев, Ozon
									</div>
									<div class="program-text">
										Эволюция маршрутизации курьеров в Ozon
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Спикеры -->
		<section
			ref="speakers"
			class="section-simple wow"
		>
			<div class="content">
				<h3 class="block-title">
					Спикеры
				</h3>
				<div class="jury-group">
					<div class="grid">
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/golang/DmitriyGordeev.jpg' | prodSrcImage"
									alt="Димитрий Гордеев"
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-name">
										Дмитрий Гордеев
									</div>
									<div class="jury-job">
										Старший разработчик Golang, МТС Банк.
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/golang/YuriyBedarev.jpg' | prodSrcImage"
									alt="Юрий Бедарев"
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-name">
										Юрий Бедарев
									</div>
									<div class="jury-job">
										Tech Lead, МТС. "Лучше плохой код с красивой кнопкой, чем
										заказчик без фичи".
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/golang/IlyaKaznacheev.jpg' | prodSrcImage"
									alt="Илья Казначеев"
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-name">
										Илья Казначеев
									</div>
									<div class="jury-job">
										Tech Lead, МТС. Основатель сообществ Golang Voronezh и SAP
										Community Voronezh, организатор митапов и конференций.
										Соавтор подкаста Z-Namespace, спикер, ментор, любитель кофе.
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/golang/klementev.jpg' | prodSrcImage"
									alt="Алексей Клементьев"
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-name">
										Алексей Клементьев
									</div>
									<div class="jury-job">
										Teamlead команды, занимающейся сервисами доставки последней
										мили в OZON
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Регистрация -->
		<section
			ref="regin"
			class="section-simple light wow"
		>
			<div class="content">
				<div class="form-block">
					<h4 class="block-title">
						Зарегистрироваться
					</h4>
					<br>
					<form class="form">
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model="name"
									type="text"
									class="field"
									placeholder="ФИО"
								>
								<div class="label">ФИО</div>
								<span
									v-if="$v.name.$error"
									class="help-block form-error"
								>
									{{
										!$v.name.required
											? "Это обязательное поле"
											: $v.name.alphaSpace
												? "Только буквы, пробел и дефис"
												: "Введите корректное имя"
									}}
								</span>
							</label>
						</div>
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model="$v.form.email.$model"
									type="email"
									class="field"
									placeholder="Email"
								>
								<div class="label">Email</div>
								<span
									v-if="$v.form.email.$error"
									class="help-block form-error"
								>
									{{
										!$v.form.email.required
											? "Это обязательное поле"
											: !$v.form.email.email
												? "Введите корректный e-mail"
												: "Ошибка поля"
									}}
								</span>
							</label>
						</div>
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model="$v.form.study.education_facility.name.$model"
									type="text"
									class="field"
									placeholder="ВУЗ"
								>
								<div class="label">ВУЗ</div>
								<span
									v-if="$v.form.study.education_facility.name.$error"
									class="help-block form-error"
								>Это обязательное поле</span>
							</label>
						</div>
						<div class="field-wrap">
							<c-select
								:options="options.grad_year"
								:name="'Год окончания:'"
								:is-error="$v.form.study.graduation_year.$error"
								@selected="selectYear"
							/>
						</div>
						<div class="field-wrap sm">
							<label class="checkbox-filter">
								<input
									v-model="$v.isPersonal.$model"
									type="checkbox"
									@change="$v.isPersonal.$touch()"
								>
								<span class="info">Соглашаюсь с условиями обработки <a
									href="/privacy-policy"
									target="_blank"
								>персональных данных</a></span>
								<div
									v-if="$v.isPersonal.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<div class="field-wrap">
							<label class="checkbox-filter">
								<input
									v-model="$v.isAgree.$model"
									type="checkbox"
									@change="$v.isAgree.$touch()"
								>
								<span class="info">Соглашаюсь с условиями <a
									href="/agreement"
									target="_blank"
								>пользовательского соглашения</a></span>
								<div
									v-if="$v.isAgree.$error"
									class="help-block form-error"
								>Необходимо подтверждение
								</div>
							</label>
						</div>
						<button
							v-if="!isSended"
							id="btnRegin_11"
							class="button orange lg"
							@click.prevent="registration"
						>
							Зарегистрироваться
						</button>
						<div
							v-else
							class="button orange lg button-preloader btnRegistration"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {required, email, sameAs} from 'vuelidate/lib/validators';
import {alphaSpace} from '@/assets/scripts/custom_validators';

export default {
    data: () => ({
        name: '',
        form: {
            email: '',
            study: {
                education: 'Неоконченное высшее',
                education_facility: {
                    name: '',
                },
                graduation_year: '',
            },
        },
        options: {
            grad_year: [],
        },
        isPersonal: false,
        isAgree: false,
        isSended: false,
    }),
    validations: {
        name: {required, alphaSpace},
        form: {
            email: {
                required,
                email,
            },
            study: {
                education_facility: {name: {required}},
                graduation_year: {required},
            },
        },
        isPersonal: {
            sameAs: sameAs(() => true)
        },
        isAgree: {
            sameAs: sameAs(() => true)
        }
    },
    computed: {
        isLogged() {
            return !!this.$store.state.user.token;
        },
    },
    created() {
        let start = 1980;
        while (start <= 2027) {
            this.options.grad_year.push(start++);
        }
    },
    methods: {
        selectYear(val) {
            this.form.study.graduation_year = val;
        },
        registration() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log('Error in Reg Form');
            } else if (!this.isSended) {
                let [last_name, first_name, middle_name] = this.name.split(' ');

                this.isSended = true;
                this.$store
                    .dispatch('user/reginEvent', {
                        data: {
                            first_name,
                            middle_name,
                            last_name,
                            ...this.form,
                            external_service: {
                                slug: 'golang',
                            },
                        },
                        path: '/?modal_open=eventSuccess',
                    })
                    .then(content => !IS_DEV && !IS_TEST ? window.location.href = content.path : false)
                    .catch((error) => this.modalOpen('regEventFail', error))
                    .finally(() => (this.isSended = false));
            }
        },
    },
};
</script>

<style lang="less">
@import '../../assets/styles/pages/golang';
</style>
